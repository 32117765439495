import { Box, TableContainer, TextareaAutosize, Tooltip, Typography } from '@mui/material'
import { Loader, SelectInput, TextFieldLabel } from 'src/components/UI'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { CURRENCY_MASK_NEGATIVE, history, ITEM_CATEGORY_FOR_FILTERS, ITEM_STATUS, PHONE_MASK_INPUT, workOrderItemFilterOptions } from 'src/helpers'
import TopInfo from './EstimateTopInfo'

// import headers from './headers'
import { InsertLink } from '@mui/icons-material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import CurrencyTextField from 'src/components/UI/CustomUI/molecules/CurrencyTextField'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { AlignType, HeaderType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { workOrdersActions } from 'src/ducks/actions'
import { getEmployeeId, getWorkOrderItems } from 'src/ducks/selectors'
import { createNumberMask } from 'text-mask-addons'
import { UpdateRequest } from '~api/types'
import styles from './styles.module.scss'

export const WorkOrders = () => {
  const { id } = useParams()

  const push = history.usePush()

  const [filterOption, setFilterOption] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [editingItemId, setEditingItemId] = useState<string>("")
  const [editedFields, setEditedFields] = useState<any>({})
  const [open, setOpen] = useState(false)
  const [openDateValue, setOpenDateValue] = useState<string>("")
  const currencyMaskNegative = createNumberMask(CURRENCY_MASK_NEGATIVE)

  const userId = useSelector(getEmployeeId)

  const dispatch = useDispatch()

  const itemStatusOptions = Object.values(ITEM_STATUS).map((status) => ({ key: status, label: status }))
  const itemCategoryOptions = Object.values(ITEM_CATEGORY_FOR_FILTERS).map((category) => ({ key: category, label: category }))

  const items = useSelector(getWorkOrderItems)

  const fetchWorkOrderItems = () => {
    dispatch(
      workOrdersActions.fetchWorkOrderItems({ id: id || "", itemState: filterOption?.key }, (succ) => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    dispatch(
      workOrdersActions.fetchGeneralSection({ id: id || "" })
    )
    dispatch(
      workOrdersActions.fetchFinancialSection({ id: id || "" })
    )
    dispatch(
      workOrdersActions.fetchContractDetails({ id: id || "" })
    )
    dispatch(
      workOrdersActions.fetchContractMilestones({ id: id || "" })
    )
  }, [id])

  useEffect(() => {
    setLoading(true)
    fetchWorkOrderItems()
  }, [id, filterOption])


  const handleFilterOptionChange = (newValue: SelectInputOption) => {
    setFilterOption(newValue)
  }

  const handleSaveData = () => {
    setLoading(true)
    const editedFieldsObjectToArrayKeys = Object.keys(editedFields)
    const editedFieldsObjectToArrayValues = Object.values(editedFields)
    const itemEdited = items?.find((item: any) => item.itemId === editingItemId)
    const itemEditedKeys = itemEdited && Object.keys(itemEdited)
    const itemEditedValues = itemEdited && Object.values(itemEdited)

    const updateRequestPaths = editedFieldsObjectToArrayKeys.map((field: any) => {
      const isEdited = itemEditedValues && itemEditedKeys && editedFields[field] !== itemEditedValues[itemEditedKeys.indexOf(field)]

      return isEdited ? {
        op: "replace",
        path: `/${field}`,
        value: editedFieldsObjectToArrayValues[editedFieldsObjectToArrayKeys.indexOf(field)] || ""
      } : {}
    }) as UpdateRequest

    const updateRequestPathsCleared = updateRequestPaths.filter((path: any) => path?.op)

    dispatch(workOrdersActions.updateWorkOrderItem({
      orderId: id || "",
      itemId: editedFields.itemId,
      userId,
      request: updateRequestPathsCleared,
    }, (succ) => {
      setLoading(false)
      setEditingItemId("")
      fetchWorkOrderItems()
    })
    )
  }

  const getOpenDateModalTitle = () => {
    switch (openDateValue) {
      case "jobStartDate":
        return "Job Start Date / Time"
      case "orderDate":
        return "Order Date"
      case "dateApprovedByCustomer":
        return "Date Approved By Customer"
      case "proDateAssigned":
        return "Pro Date Assigned"
      case "dateOfScheduledService":
        return "Date of Scheduled Service"
      case "dateRejected":
        return "Date Rejected"
      case "dateProComplete":
        return "Date Pro Complete"
      case "dateQualityReviewComplete":
        return "Date Quality Review Complete"
      default:
        return ""
    }
  }

  const headers: HeaderType[] = [
    // {
    //   id: 'editItem',
    //   label: 'Edit',
    //   custom: true,
    //   padding: "0px 4px",
    //   align: 'left' as AlignType,
    //   stickyLeft: "0px",
    //   highlightRows: true,
    //   minWidth: 30,
    //   maxWidth: 50,
    //   Element: (row: any): JSX.Element => {
    //     const isEditingItem = editingItemId === row.itemId;

    //     const handleEditItem = () => {
    //       if (loading) return;
    //       if (isEditingItem) {
    //         handleSaveData()
    //       } else {
    //         setEditingItemId(row.itemId)
    //         setEditedFields(row)
    //       }
    //     }

    //     return loading ? <Loader size={20} margin="4px " /> : (
    //       <Icon onClick={handleEditItem} color="var(--blue700)" name={isEditingItem ? "Save" : "Edit"} sx={{ cursor: "pointer" }} />
    //     )
    //   },
    // },
    {
      id: 'workOrderItem',
      label: 'Work Order Item',
      custom: true,
      padding: "0px 8px",
      align: 'left' as AlignType,
      stickyLeft: "0px",
      highlightRows: true,
      minWidth: 400,
      Element: (row: any): JSX.Element => {

        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.workOrderItem || "-"}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'jobStartedAt',
      label: <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Job Start</Typography>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Date / Time</Typography>
      </Box>,
      custom: true,
      padding: "0px 8px",
      align: 'center' as AlignType,
      minWidth: 180,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem || loading) return;
          setOpen(true)
          setOpenDateValue("jobStartDate")
        }
        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.jobStartDate ? moment(editedFields.jobStartDate || '').format("MM-DD-YY hh:mm A") : moment().format("MM-DD-YY hh:mm A") : row.jobStartDate ? moment(row.jobStartDate).format("MM-DD-YY hh:mm A") : "-"}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'status',
      label: 'Status',
      custom: true,
      padding: "0px 8px",
      align: 'center' as AlignType,
      minWidth: 120,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        const isBlue = row.status === 'ACCEPTED' || row.status === 'COMPLETE' || row.status === 'APPROVED';

        return isEditingItem ? <SelectInput
          options={itemStatusOptions}
          placeholder=''
          onChange={(newSelected: SelectInputOption) => {
            setEditedFields((prev: any) => ({ ...prev, status: newSelected.key }))
          }}
          disableClearable
          value={editedFields.status}
          size='small'
          sx={{ pointerEvents: loading ? "none" : "auto" }}
        /> : (
          <Box style={{ padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName} style={{ color: isBlue ? 'var(--blue700)' : "var(--gray700)", backgroundColor: isBlue ? "var(--blue100)" : "var(--gray100)", padding: "0px 4px", borderRadius: "4px" }}>
              {row.status}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'description',
      label: 'Description',
      custom: true,
      padding: "0px 8px",
      minWidth: 400,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return isEditingItem ? <TextareaAutosize style={{ width: "300px" }} defaultValue={editedFields.description} onBlur={(e) => setEditedFields((prev: any) => ({ ...prev, description: e.target.value }))} /> : (
          (row.description && row.description.length > 70) ? <Tooltip
            title={
              <Box style={{ padding: '2px 10px' }}>
                <Typography className={styles.Companies__companyName}>
                  {row.description || '-'}
                </Typography>
              </Box>
            }
            placement='top'
            enterDelay={500}
            leaveDelay={200}
            arrow
          >
            <Box style={{ padding: '2px 10px' }}>
              <Typography className={styles.Companies__companyName}>
                {row.description ? row.description.slice(0, 70) + '...' || '-' : "-"}
              </Typography>
            </Box>
          </Tooltip>
            : <Box style={{ padding: '2px 10px' }}>
              <Typography className={styles.Companies__companyName}>
                {row.description || '-'}
              </Typography>
            </Box>
        )
      },
    },
    {
      id: 'tradeCategory',
      label: 'Trade Category',
      custom: true,
      padding: "0px 8px",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        let category: keyof typeof ITEM_CATEGORY_FOR_FILTERS = row.tradeCategory;
        const editedFieldsCategory: keyof typeof ITEM_CATEGORY_FOR_FILTERS = editedFields.tradeCategory;
        const isEditingItem = editingItemId === row.itemId;

        return isEditingItem ? <SelectInput
          options={itemCategoryOptions}
          onChange={(newSelected: SelectInputOption) => {
            const itemCategoryKeys = Object.keys(ITEM_CATEGORY_FOR_FILTERS)
            const itemCategoryValues = Object.values(ITEM_CATEGORY_FOR_FILTERS)
            const newSelectedIndex = itemCategoryValues.indexOf(newSelected?.label as any)
            const newSelectedKey = itemCategoryKeys[newSelectedIndex]
            setEditedFields((prev: any) => ({ ...prev, tradeCategory: newSelectedKey }))
          }}
          disableClearable
          value={ITEM_CATEGORY_FOR_FILTERS[editedFieldsCategory]}
          size='small'
        /> : (
          <Box style={{ padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {ITEM_CATEGORY_FOR_FILTERS[category] || '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'originalContract',
      label: 'Original Contract',
      custom: true,
      padding: "0px 8px",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.originalContract ? 'YES' : 'NO'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'orderDate',
      label: 'Order Date',
      custom: true,
      padding: "0px 8px",
      minWidth: 100,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("orderDate")
        }
        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.orderDate ? moment(editedFields.orderDate).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.orderDate ? moment(row.orderDate).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'approvedByCustomerAt',
      label: <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Date Approved</Typography>
        <Typography variant={'h6Bold'} color="var(--navy-700)">By Customer</Typography>
      </Box>,
      custom: true,
      padding: "0px 8px",
      minWidth: 130,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("dateApprovedByCustomer")
        }
        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.dateApprovedByCustomer ? moment(editedFields.dateApprovedByCustomer).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.dateApprovedByCustomer ? moment(row.dateApprovedByCustomer).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    // {
    //   id: 'quantity',
    //   label: <Box sx={{ display: "flex", flexDirection: "column" }}>
    //     <Typography variant={'h6Bold'} color="var(--navy-700)">Quantity</Typography>
    //   </Box>,
    //   custom: true,
    //   padding: "0px 8px",
    //   minWidth: 80,
    //   color: "var(--gray800)",
    //   align: 'center' as AlignType,
    //   Element: (row: any): JSX.Element => {
    //     const isEditingItem = editingItemId === row.itemId;
    //     return (
    //       <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
    //         <Typography className={styles.Companies__companyName}>
    //           {row?.quantity || "-"}
    //         </Typography>
    //       </Box>
    //     )
    //   },
    // },
    // {
    //   id: 'materialCost',
    //   label: <Box sx={{ display: "flex", flexDirection: "column" }}>
    //     <Typography variant={'h6Bold'} color="var(--navy-700)">Material</Typography>
    //     <Typography variant={'h6Bold'} color="var(--navy-700)">Cost</Typography>
    //   </Box>,
    //   custom: true,
    //   padding: "0px 8px",
    //   minWidth: 80,
    //   color: "var(--gray800)",
    //   align: 'center' as AlignType,
    //   Element: (row: any): JSX.Element => {
    //     const isEditingItem = editingItemId === row.itemId;
    //     return (
    //       <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
    //         <Typography className={styles.Companies__companyName}>
    //           {row.materialCost || "-"}
    //         </Typography>
    //       </Box>
    //     )
    //   },
    // },
    // {
    //   id: 'margin',
    //   label: <Box sx={{ display: "flex", flexDirection: "column" }}>
    //     <Typography variant={'h6Bold'} color="var(--navy-700)">Margin</Typography>
    //   </Box>,
    //   custom: true,
    //   padding: "0px 8px",
    //   minWidth: 80,
    //   color: "var(--gray800)",
    //   align: 'center' as AlignType,
    //   Element: (row: any): JSX.Element => {
    //     const isEditingItem = editingItemId === row.itemId;
    //     return (
    //       <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
    //         <Typography className={styles.Companies__companyName}>
    //           {row.margin || "-"}
    //         </Typography>
    //       </Box>
    //     )
    //   },
    // },
    {
      id: 'proPrice',
      label: <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Pro</Typography>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Price</Typography>
      </Box>,
      custom: true,
      padding: "0px 8px",
      minWidth: 80,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.proPrice}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'customerPrice',
      label: 'Customer Price',
      custom: true,
      padding: "0px 8px",
      align: 'center' as AlignType,
      minWidth: 108,
      color: "var(--gray800)",
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return isEditingItem ? <CurrencyTextField
          autoComplete="off"
          label={<></>}
          value={editedFields.customerPrice}
          onBlur={(val) => {
            setEditedFields((prev: any) => ({ ...prev, customerPrice: val }))
          }}
          size="small"
          labelVariant="h6"
          mask={currencyMaskNegative}
        /> : (
          <Box style={{ padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.customerPrice}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'proAssignedAt',
      label: 'Pro Date Assigned',
      custom: true,
      padding: "0px 8px",
      align: 'center' as AlignType,
      minWidth: 110,
      color: "var(--gray800)",
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("proDateAssigned")
        }
        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.proDateAssigned ? moment(editedFields.proDateAssigned).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.proDateAssigned ? moment(row.proDateAssigned).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'proName',
      label: 'Pro Name',
      custom: true,
      padding: "0px 8px",
      minWidth: 125,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName} style={{ color: '#3A66FF' }}>
              {row.proName || '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'proContactPhone',
      label: 'Pro Contact Phone',
      custom: true,
      padding: "0px 8px",
      minWidth: 125,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return isEditingItem ? <TextFieldLabel
          label={<></>}
          defaultValue={editedFields.proContactPhone}
          placeholder="876-543-1223"
          mask={PHONE_MASK_INPUT}
          className={styles.TextFieldLabel}
          onBlur={(e) => setEditedFields((prev: any) => ({ ...prev, proContactPhone: e.target.value }))}
        /> : (
          <Box style={{ padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.proContactPhone || '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'proLicenseStatus',
      label: 'Pro License Status',
      custom: true,
      padding: "0px 8px",
      minWidth: 125,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName} style={{ color: row.proLicenseStatus === 'Expired' ? '#E01F1F' : '#5B5D71' }}>
              {row.proLicenseStatus || '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'scheduledServiceAt',
      label: 'Date of Scheduled Service',
      custom: true,
      padding: "0px 8px",
      minWidth: 125,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("dateOfScheduledService")
        }
        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.dateOfScheduledService ? moment(editedFields.dateOfScheduledService).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.dateOfScheduledService ? moment(row.dateOfScheduledService).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'dateRejected',
      label: 'Date Rejected',
      custom: true,
      padding: "0px 8px",
      color: "var(--gray800)",
      minWidth: 108,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("dateRejected")
        }

        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.dateRejected ? moment(editedFields.dateRejected).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.dateRejected ? moment(row.dateRejected).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'removedFromScope',
      label: 'Removed from Scope',
      custom: true,
      padding: "0px 8px",
      minWidth: 125,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.removedFromScope || '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'materialSelectionRequired',
      label: 'Requires Material Selection',
      custom: true,
      padding: "0px 8px",
      color: "var(--gray800)",
      minWidth: 125,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName} fontFamily="var(--font-bold)" color="var(--gray700)">
              {row.requiresMaterialSelection ? 'YES' : 'NO'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'materialSelectionCompletedAt',
      label: <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Date Material</Typography>
        <Typography variant={'h6Bold'} color="var(--navy-700)">Selection Complete</Typography>
      </Box>,
      custom: true,
      color: "var(--gray800)",
      padding: "0px 8px",
      minWidth: 135,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.dateMaterialSelectionComplete || "-"}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'proCompleteAt',
      label: 'Date Pro Complete',
      custom: true,
      color: "var(--gray800)",
      padding: "0px 8px",
      minWidth: 110,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("dateProComplete")
        }

        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.dateProComplete ? moment(editedFields.dateProComplete).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.dateProComplete ? moment(row.dateProComplete).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'qualityReviewCompleteAt',
      label: 'Date Quality Review Complete',
      custom: true,
      padding: "0px 8px",
      color: "var(--gray800)",
      minWidth: 135,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;

        const handleClick = () => {
          if (!isEditingItem) return;
          setOpen(true)
          setOpenDateValue("dateQualityReviewComplete")
        }

        return (
          <Box onClick={handleClick} style={{ cursor: isEditingItem ? "pointer" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {isEditingItem ? editedFields.dateQualityReviewComplete ? moment(editedFields.dateQualityReviewComplete).format("MM-DD-YY") : moment().format("MM-DD-YY") : row.dateQualityReviewComplete ? moment(row.dateQualityReviewComplete).format("MM-DD-YY") : '-'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'reworkRequired',
      label: 'Rework Required',
      custom: true,
      padding: "0px 8px",
      minWidth: 80,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ cursor: isEditingItem ? "not-allowed" : "auto", padding: '2px 10px' }}>
            <Typography className={styles.Companies__companyName} fontFamily="var(--font-bold)" color="var(--gray700)">
              {row.reworkRequired ? 'YES' : 'NO'}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'materialSelectionLink',
      label: 'Material Selection Link',
      custom: true,
      padding: "0px 8px",
      color: "var(--gray800)",
      minWidth: 125,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return (
          <Box style={{ color: '#3A66FF', cursor: isEditingItem ? "not-allowed" : "pointer" }} display='flex' gap='4px' alignItems='center' justifyContent="center" onClick={() => isEditingItem ? {} : window.open(row.materialSelectionLink, "_blank")}>
            <Typography className={styles.Companies__companyName}>
              Materials
            </Typography>
            <InsertLink style={{ width: '12px', height: '12px' }} />
          </Box>
        )
      },
    },
    {
      id: 'comments',
      label: 'Comments',
      custom: true,
      padding: "0px 8px",
      minWidth: 200,
      color: "var(--gray800)",
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        const isEditingItem = editingItemId === row.itemId;
        return isEditingItem ? <TextareaAutosize style={{ width: "300px" }} defaultValue={editedFields.comments} onBlur={(e) => setEditedFields((prev: any) => ({ ...prev, comments: e.target.value }))} /> : (
          (row.comments && row.comments.length > 20) ? <Tooltip
            title={
              <Box style={{ padding: '2px 10px' }}>
                <Typography className={styles.Companies__companyName}>
                  {row.comments || '-'}
                </Typography>
              </Box>
            }
            placement='top'
            enterDelay={500}
            leaveDelay={200}
            arrow
          >
            <Box style={{ padding: '2px 10px' }}>
              <Typography className={styles.Companies__companyName}>
                {row.comments ? row.comments.slice(0, 20) + '...' || '-' : "-"}
              </Typography>
            </Box>
          </Tooltip>
            : <Box style={{ padding: '2px 10px' }}>
              <Typography className={styles.Companies__companyName}>
                {row.comments || '-'}
              </Typography>
            </Box>
        )
      },
    },
  ]

  const handleGoBack = () => {
    push(`/project-coordinator/${userId}`)
  }
  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
        <Box onClick={handleGoBack} display="flex" flexDirection='row' style={{ gap: '4px', cursor: "pointer", marginTop: "-16px" }}>
          <Icon name="ArrowBack" color="var(--blue700)" />
          <Typography variant="h6Bold" color="var(--blue700)">
            Back To Project Coordinator View
          </Typography>
        </Box>
        <TopInfo loading={false}>
          <Box width="65%">
            <SelectInput label="Filter By" options={workOrderItemFilterOptions} onChange={handleFilterOptionChange} />
          </Box>
        </TopInfo>
        <TableContainer className={styles.TableScrollBar} style={{ maxHeight: "51vh" }}>
          <Table
            headers={headers}
            defaultOrder="desc"
            // loading={false}
            stickyHeader
            sortable
            highlightedRows
            hasPagination={false}
            className={styles.workOrdersTable}
            hasScroll
            loading={loading}
          >
            {items || []}
          </Table>
        </TableContainer>
      </Box>
      <Modal
        setOpen={setOpen}
        open={open}
        showClose
        title=''
        size='md'
        dialogContentSx={{ minHeight: "350px" }}
      // actions={modalAction}
      >
        <Box>
          <DatePicker
            label={getOpenDateModalTitle()}
            value={editedFields[`${openDateValue}`] ? new Date(editedFields[`${openDateValue}`]) : new Date()}
            defaultOpenCalendar
            styleAlert={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            onChange={(e) => { setEditedFields((prev: any) => ({ ...prev, [`${openDateValue}`]: e })) }}
            allowTimePicker
            align='left1'
            style={{ width: '100%' }}
          />
        </Box>
      </Modal>
    </>
  )
}
